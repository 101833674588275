@import "//codepen.io/chrisdothtml/pen/ojLzJK.css";

.social-btns .btn,
.social-btns .btn:before,
.social-btns .btn .fa {
  transition: all 0.35s;
  transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
  z-index: 0.8;
}
.social-btns .btn:before {
  top: 90%;
  left: -110%;
}
.social-btns .btn .fa {
  transform: scale(0.8);
}
.social-btns .btn.email:before {
  background-color: #313349;
}
.social-btns .btn.email {
  color: #313349;
}
.social-btns .btn.linkedin:before {
  background-color: #313349;
}
.social-btns .btn.linkedin {
  color: #313349;
}
.social-btns .btn.github:before {
  background-color: #313349;
}
.social-btns .btn.github fa {
  color: #313349;
}
.social-btns .btn:focus:before,
.social-btns .btn:hover:before {
  top: -10%;
  left: -10%;
}
.social-btns .btn:focus .fa,
.social-btns .btn:hover .fa {
  color: #f4f1de;
  transform: scale(1);
}

.social-btns .btn:focus svg path,
.social-btns .btn:hover svg path {
  color: #f4f1de;
}

.social-btns {
  height: 100vh;
  justify-content: flex-end;

  display: flex;
  flex-direction: column;
  margin: auto;
  font-size: 0;
  text-align: center;
  position: absolute;

  bottom: 0.5rem;
  left: 0.5rem;
}
.social-btns .btn {
  display: block;
  background-color: #f4f1de;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin: 10px 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 28%;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  opacity: 0.99;
}
.social-btns .btn:before {
  content: "";
  width: 120%;
  height: 120%;
  position: absolute;
  transform: rotate(45deg);
}
.social-btns .btn .fa {
  font-size: 38px;
  vertical-align: middle;
}

.fa {
  width: 30px;
  height: 40px;

  margin: 0;
  padding: 0;

  z-index: 100;

  --fa-border-color: WhiteSmoke;
}

.btn svg path::before {
  color: #e07a5f;
  opacity: 0.6;
}

.btn svg path {
  color: #beb7a4;
  opacity: 1;
}
