.featured-section {
  display: flex;
  flex-direction: column;
  grid-template-columns: 2fr 1fr;
  align-items: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;

  h6 {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 500;
  }

  .featured-row-layout,
  .featured-column-layout {
    display: grid;
  }

  .featured-row-layout {
    grid-template-rows: repeat(2, auto);

    img {
      width: 50%;
      // height: 100%;
      object-fit: cover;
      border-style: solid;
      border-color: #f4f1de;
      border-radius: 1rem;
    }
  }

  .featured-column-layout {
    grid-template-columns: auto auto;
    align-items: flex-end;

    img {
      width: 100%;
      height: 125vh;
      object-fit: contain;
    }
  }
}
