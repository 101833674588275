@import "./vars.css";
/* General */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: futura-pt, sans-serif;
  background-size: cover;
}

a {
  text-decoration: none;
  color: var(--text-2);
}

body {
  /* background-color: #4bbfc3; */
  background-color: none;
  color: #4e598c;
}

.work {
  position: relative;
  color: #4e598c;
  font-family: CraftyFont-Regular-Regular;
  background: none;
  font-size: 2.5rem;
}

.work:after {
  content: "WORK";
  color: #d98496;
  font-family: CraftyFont-PolkaDot-Regular;
  font-size: 2.5rem;
  position: absolute;
  top: 0;
  left: 0;
}

.contact {
  position: relative;
  color: #d98496;
  font-family: CraftyFont-Regular-Regular;
  background: none;
  font-size: 2.5rem;
}

.contact:after {
  content: "CONTACT";
  color: #4e598c;
  font-family: CraftyFont-PolkaDot-Regular;
  font-size: 2.5rem;
  position: absolute;
  top: 0;
  left: 0;
}

/* Home */
.text-container {
  position: fixed;
  top: 50%;
  -webkit-top: 50%;
  -webkit-transform: translateY(-50%);
  text-align: left;
  display: flexbox;
  margin-left: 5em;
  background: none;
}

.bottom {
  background: none;
  font-size: 22px;
  width: 100vw;

  position: fixed;
  bottom: 1em;

  display: flexbox;
  flex-direction: row;
}

.gg-chevron-down {
  margin-left: auto;
  margin-right: auto;
  color: #262626;
}

.s1 {
  width: 100vw;
  height: 100vh;
}

.s2 {
  background: -webkit-gradient(
    linear,
    top left,
    bottom left,
    from(#e0e5ec),
    to(#7baabe)
  );
  background: -webkit-linear-gradient(#e0e5ec, #7baabe);
  background: linear-gradient(#e0e5ec, #7baabe);
}

.s2 h1 {
  color: white;
  text-align: left;
  margin-left: 2em;
  font-size: 4rem;
}

/* Work */
.text-container-2 {
  position: relative;
  top: 30%;
  -webkit-top: 30%;
  -webkit-transform: translateY(-30%);
  text-align: left;
  display: flexbox;
  margin-left: 5em;
}

.card {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  height: 75vh;
  width: 84vw;

  margin-left: 8vw;

  margin-top: 2.5em;
}

.wMain2 {
  background: #7f7fd5; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #7f7fd5,
    #86a8e7,
    #91eae4
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #7f7fd5,
    #86a8e7,
    #91eae4
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.w1 {
  background: #7f7fd5; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #7f7fd5,
    #86a8e7,
    #91eae4
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #7f7fd5,
    #86a8e7,
    #91eae4
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.w2 {
  background: #74ebd5; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #91eae4,
    #acb6e5
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #91eae4,
    #acb6e5
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.w3 {
  background: #acb6e5; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #acb6e5,
    #cf8bf3,
    #fdb99b
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #acb6e5,
    #cf8bf3,
    #fdb99b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.w4 {
  background: #d53369; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #d53369,
    #fdb99b
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #d53369,
    #fdb99b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.w5 {
  background: #12c2e9; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #12c2e9,
    #d53369
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #12c2e9,
    #d53369
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.w6 {
  background: #12c2e9; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #91eae4,
    #12c2e9
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #91eae4,
    #12c2e9
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.w7 {
  background: #12c2e9; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #7f7fd5,
    #91eae4
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #7f7fd5,
    #91eae4
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
/* Banksy */
.img-bg {
  top: 0;
  width: 100%;
  position: absolute;
  height: 100vh;
  background-image: url(../img/banksy.png);
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.pin-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1500px;
  height: 300px;

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
}

.title2 {
  position: relative;
  align-self: center;
}

.pin {
  width: 60px;
  height: 60px;
  color: #fff;
  text-align: center;
  position: absolute;
}

.pin:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  opacity: 0;
  animation: puls-effect 2s ease;
}

.pin i {
  font-size: 40px;
  line-height: 60px;
  transition: all 0.3s;
  opacity: 70%;
}

.pin:hover .tooltip-content,
.pin:active .tooltip-content {
  opacity: 1;
  pointer-events: all;
  display: block;
}

.pin:hover i,
.pin:active i {
  cursor: pointer;
  font-size: 50px;
  color: #ed1234;
}
/* Image Pins */

#pin1 {
  left: 100px;
  top: 70px;
}

#pin2 {
  left: 500vh;
  top: 200vh;
}

#pin3 {
  left: 1000vh;
  top: 140vh;
}

#pin4 {
  left: 1300px;
  top: 160px;
}

#pin5 {
  left: 1300px;
  top: 160px;
}

#pin5 .tooltip-content {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}

#pin3 .tooltip-content {
  width: 60vw;
  right: 0;
  left: auto;
  top: auto;
  bottom: 0;
}

/* Max Media Query */

@media (max-width: 1500px) {
  .pin-container {
    width: 1000px;
  }

  #pin2 {
    left: 500px;
    top: 250px;
  }

  #pin1 {
    left: 280px;
    top: 290px;
  }

  #pin3 {
    left: 463px;
    top: 80px;
  }

  #pin4 {
    left: 650px;
    top: 55px;
  }
  #pin5 {
    left: 650px;
    top: 450px;
  }

  #pin1 .tooltip-content {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
  }
}

/* Mobile Media Query */

@media (max-width: 700px) {
  .pin-container {
    width: 500px;
  }

  #pin3 {
    left: 225px;
    top: 100px;
  }

  #pin1 {
    left: 70px;
    top: 261px;
  }

  #pin4 {
    left: 350px;
    top: 40px;
  }

  #pin5 {
    left: 370px;
    top: 410px;
  }

  #pin2 {
    left: 245px;
    top: 223px;
  }

  #pin3 .tooltip-content {
    right: 0;
    left: auto;
    top: 0;
    bottom: auto;
  }

  #pin4 .tooltip-content {
    top: auto;
    left: auto;

    bottom: 0;
    right: 0;
    width: 60vw;
  }

  #pin5 .tooltip-content {
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
    width: 60vw;
  }

  #pin1 .tooltip-content {
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
  }

  #pin2 .tooltip-content {
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 50vw;
  }
}

.tooltip-content {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 20px #00000020;
  padding: 30px;
  border-radius: 8px;
  width: 90vw;
  max-width: 300px;
  opacity: 0;
  pointer-events: none;

  z-index: 2;
  position: relative;
}

.tooltip-content .content {
  color: black;
}

/* Puls Effect For Pins */

@keyframes puls-effect {
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

.bounce {
  -moz-animation: bounce 3s normal;
  -webkit-animation: bounce 3s normal;
  animation: bounce 3s normal;
}

@keyframes bounce {
  0%,
  1%,
  20%,
  30%,
  40%,
  50%,
  60%,
  80%,
  100% {
    transform: translateY(0);
  }
  70% {
    transform: translateY(-20px);
  }
  90% {
    transform: translateY(-10px);
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Doodle */

.img-fg {
  width: 100%;
  height: 100vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  background-image: url(../img/doodleFG.png);

  border-style: solid;

  border-color: #bec3c9;

  top: -10vw;
  left: 0;
  position: fixed;
  pointer-events: none;
  border-style: solid;
  border-color: rebeccapurple;
}

.img-bg3 {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../img/doodleBG.png);
  background-size: contain;
  background-position: center;
  position: relative;
  overflow: hidden;
  top: -10vw;
}

.doodle-container {
  margin: 0;
  position: absolute;
  width: fit-content;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-color: rebeccapurple;
  animation: fadeInAnimation ease 2;
  color: white;
  mix-blend-mode: exclusion;
}

.doodlefont:hover,
.doodlefont:active {
  color: darkgreen;
  animation: fadeInAnimation ease 1;
}

.doodlefont {
  position: relative;
  color: pink;
  mix-blend-mode: exclusion;
  font-family: forestFont;
  background: none;
  font-size: 18rem;

  align-self: center;
  display: inline-block;
}

.style2 {
  font-family: safariFont;
}

.style3 {
  font-family: oceanFont;
}

.section {
  text-align: center;
}

/* Navigation */
.logoButton {
  position: fixed;
  top: 0.2em;
  left: 0.4em;
  display: flex;
  flex-direction: row;
  background: none;

  border: none;

  color: var(--text-1);
  background: none;
  font-size: 4rem;

  mix-blend-mode: exclusion;
}

.fp-controlArrow.fp-prev {
  left: 15px;
  width: 0;
  border-width: 38.5px 34px 38.5px 0;
  border-color: transparent #fff transparent transparent;
}
.fp-controlArrow.fp-next {
  right: 15px;
  border-width: 38.5px 0 38.5px 34px;
  border-color: transparent transparent transparent #fff;
}

.custom-cursor {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  mix-blend-mode: difference;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  transition: transform 350ms ease;
  transform: translate(-50%, -50%) scale(0.1);
  z-index: 1000;
}
.custom-cursor--link {
  transform: translate(-50%, -50%) scale(1.5);
  pointer-events: none;
}
.block__title {
  margin-bottom: 1em;
  font-size: 4rem;
  font-weight: 300;
  text-align: center;
}
.block__link {
  padding: 0.5em;
  text-decoration: none;
  transition: letter-spacing 200ms ease;
}
.block__link:hover {
  letter-spacing: 0.1em;
}
