.about-section {
  p {
    font-size: 70px;
    line-height: 1.12;
    color: wheat;

    opacity: 0;
    div {
      opacity: 0;
      transform: translate(0);
    }

    &.is-reveal {
      opacity: 1;
    }

    &.is-reveal2 {
      text-align: right;

      opacity: 1;
    }
  }
}

.blogContainer {
  width: 60vw;
}
