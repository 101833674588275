@import url("https://fonts.googleapis.com/css?family=Bebas+Neue|Poppins:300,400&display=swap");

@import url("https://use.typekit.net/dxb2njt.css");

@import "./vars.css";

body {
  background-color: var(--bg);
  margin-left: 5vw;
  margin-right: 5vw;
  font-family: avocado;
}

main {
  background-color: var(--bg);
  margin-left: 5vw;
  margin-right: 5vw;
}

* {
  color: var(--text-1);
}

.container {
  background-color: var(--bg);
  position: relative;

  display: flex;
  justify-content: center;

  .page {
    position: absolute;
    border: solid;
    border-color: turquoise;

    .inner {
      display: flex;
      justify-content: center;

      h1 {
        font-family: avocado;
        font-size: 52px;
        letter-spacing: 0.1rem;
        .line-wrap {
          overflow: hidden;
          background-color: red;
          height: 66px;
        }
      }
      p {
        font-family: avocado;
        margin-top: 200px;
        width: 340px;
        font-weight: 300;
        line-height: 24px;
        font-size: 14px;
      }
    }
  }
}

.other {
  height: 20vh;
}

.main-container {
  top: 0;
  margin-top: 0;
  min-height: 100vh;

  padding: 0 5vw;

  background-color: var(--bg);
}

// work

.workContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;

  font-family: avocadoBold;
}

.logoContainerSpacer {
  width: 6rem;
  text-align: right;
  padding-right: 0;
  margin-right: 0;

  left: 0;

  padding-left: 0;
  margin-left: 0;
  overflow: visible;

  li {
    list-style: none;
    text-align: right;
    right: 0;

    overflow: visible;
    white-space: nowrap;

    margin-left: 0;
    padding-left: 0;
  }
  ul {
    right: 0;
    left: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    text-align: right;
    overflow: visible;

    width: 100%;
  }
  display: flex;
}

/* Solid border */
.solid {
  border-top: 1px solid #bbb;
  width: 75vw;
  opacity: 0.5;
}

.sectionMenuItem {
  margin-top: 0;
  padding-top: 0;
  top: 0;

  z-index: 0;
  h1 {
    margin-top: 0rem;
    padding-top: 0;
    margin-bottom: 1rem;
    padding-bottom: 0;
    top: 0;
    bottom: 0;

    font-family: avocadoBold;
  }

  h2 {
    margin-top: 0;
    padding-top: 0;
    top: 0;

    font-family: avocadoBold;

    font-size: 1rem;

    opacity: 0.5;
  }

  margin-left: 3rem;

  y: -500;
}

.activeWorkLink {
  color: #e07a5f;
  opacity: 1;
  font-weight: bold;
  text-decoration: underline;
}

.sectionMenuContainer {
}

.workLink {
  opacity: 1;
}

// hover testing

html,
body,
#app {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.main-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;

  align-items: center;
}

html {
  background-size: cover;
  color: #fff;
  overflow: hidden;
  --easing: cubic-bezier(0.5, 0.5, 0.2, 1);
}

.image-hover {
  display: block;
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 2vh 0;
  transform-origin: top left;
  animation: skwoop 0.7s var(--easing) backwards;
  animation-duration: calc(0.6s + (var(--i) * 0.1s));
  animation-delay: calc((var(--i) * 50ms));
  cursor: pointer;

  @keyframes skwoop {
    from {
      opacity: 0;
      transform: translateY(5vh) skewY(5deg);
    }
  }

  .title {
    font-weight: 900;
    font-size: 16em;

    letter-spacing: 0.05ch;

    &::after {
      content: attr(data-title);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      color: transparent;
      -webkit-text-stroke: 2px #fff;
      //-webkit-text-stroke: 2px red;
    }
  }

  > img {
    // display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 40%;
    max-height: 60vh;
    pointer-events: none;
    z-index: 2;
    visibility: hidden;
    opacity: 0;

    mix-blend-mode: exclusion;
    transition: opacity 0.2s linear, visibility 0s linear 0.2s;
  }

  &:hover,
  &:focus {
    z-index: 10;
    .title::after {
      display: block;
    }
    > img {
      transition-delay: 0s;
      visibility: visible;
      opacity: 1;
    }
  }
}

.image-hover > img {
  --x-px: calc(var(--x) * 1px);
  --y-px: calc(var(--y) * 1px);
  top: 0;
  left: 0;
  transform: translate(var(--x-px), var(--y-px)) translate(-50%, -50%);
}

.style1 {
  font-family: forestFont;
}

.style2 {
  font-family: safariFont;
}

.style3 {
  font-family: oceanFont;
}
