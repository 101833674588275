@import "../../styles/vars.css";
@import "../../styles/fonts.css";

li.circle-checkmark::before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  border: solid 8px #f9dd94;
  border-radius: 8px;
}

li.circle-checkmark::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 3px;
  height: 6px;
  border: solid #7eb4e2;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

li {
  list-style: none;
  position: relative;
  padding: 0 0 0 20px;
}

.oll {
  list-style: decimal;
}

.workContainerPage {
  width: 90vw;
  top: 0;

  p {
    width: 80%;
    font-size: large;
  }

  .p2 {
    align-self: flex-end;
    text-align: right;
  }

  ul {
    align-self: flex-start;

    direction: ltr;
    margin: 0;
    padding: 0;
  }

  div {
    align-items: baseline;
    display: flex;
    flex-direction: column;
  }

  h1 {
    top: 0;
    font-size: 5vw;
    margin-top: 0;
    margin-bottom: 0rem;
    align-self: flex-start;
  }

  h2 {
    align-self: flex-start;

    font-size: 3vw;
    margin-bottom: 0;
    margin-top: 0.5vw;
  }

  h3 {
    align-self: flex-start;

    font-size: 2vw;
    margin-bottom: 0;
    margin-top: 0.5vw;
  }

  h4 {
    align-self: flex-end;

    font-size: 0.75vw;
    margin-bottom: 0;
    margin-top: 0.5vw;
  }

  h5 {
    align-self: flex-start;

    font-size: 0.75vw;
    margin-bottom: 0;
    margin-top: 0.5vw;
  }

  h6 {
    font-size: 0.75vw;
    margin-bottom: 0;
    margin-top: 0;
    align-self: flex-end;
    width: 60vw;
    text-align: right;
  }

  img {
    align-self: flex-start;
  }

  .image-container2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .image3 {
    flex-direction: column;
    align-items: flex-start;
  }

  .image4 {
    flex-direction: row;
  }
  .phone img {
    width: 20vw;
  }

  .card2 {
    width: 40vw;
    align-self: flex-start;
  }
  color: wheat;
}

// arrrroowww
$black: #4c4c4c;
$white: #eaeaea;
$gray: #808080;

$text-arrow-space: 24px;
$shaft-width: 3px;
$newshaft-width: 64px;
$shaft-thickness: 3px;
$arrow-head-width: 16px;
$arrow-head-thickness: $shaft-thickness;

.the-arrow {
  width: $shaft-width;
  transition: all 0.2s;

  &.-left {
    position: absolute;
    top: 60%;
    left: 0;

    > .shaft {
      width: 0;
      background-color: $black;

      &:before,
      &:after {
        width: 0;
        background-color: $black;
      }

      &:before {
        transform: rotate(0);
      }

      &:after {
        transform: rotate(0);
      }
    }
  }

  li.circle-checkmark::before {
    content: "";
    position: absolute;
    left: 0;
    top: 2px;
    border: solid 8px #f9dd94;
    border-radius: 8px;
  }

  li.circle-checkmark::after {
    content: "";
    position: absolute;
    left: 5px;
    top: 5px;
    width: 3px;
    height: 6px;
    border: solid #7eb4e2;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  &.-right {
    top: 3px;

    > .shaft {
      width: $shaft-width;
      transition-delay: 0.2s;

      &:before,
      &:after {
        width: $arrow-head-width;
        transition-delay: 0.3s;
        transition: all 0.5s;
      }

      &:before {
        transform: rotate(40deg);
      }

      &:after {
        transform: rotate(-40deg);
      }
    }
  }

  > .shaft {
    background-color: $black;
    display: block;
    height: $shaft-thickness;
    position: relative;
    transition: all 0.2s;
    transition-delay: 0;
    will-change: transform;

    &:before,
    &:after {
      background-color: $black;
      content: "";
      display: block;
      height: $arrow-head-thickness;
      position: absolute;
      top: 0;
      right: 0;
      transition: all 0.2s;
      transition-delay: 0;
    }

    &:before {
      transform-origin: top right;
    }

    &:after {
      transform-origin: bottom right;
    }
  }
}

// Animated Arrow Button

.animated-arrow {
  display: inline-block;
  color: $black;
  font-size: 1.25em;
  font-style: italic;
  text-decoration: none;
  position: relative;
  transition: all 0.2s;

  &:hover {
    color: $gray;

    > .the-arrow.-left {
      > .shaft {
        width: $newshaft-width;
        transition-delay: 0.1s;
        background-color: $gray;

        &:before,
        &:after {
          width: $arrow-head-width;
          transition-delay: 0.1s;
          background-color: $gray;
        }

        &:before {
          transform: rotate(40deg);
        }

        &:after {
          transform: rotate(-40deg);
        }
      }
    }

    > .main {
      transform: translateX($shaft-width + $text-arrow-space);
      transform: translateX($newshaft-width + $text-arrow-space);

      > .the-arrow.-right {
        > .shaft {
          width: 0;
          transform: translateX(200%);
          transition-delay: 0;

          &:before,
          &:after {
            width: 0;
            transition-delay: 0;
            transition: all 0.1s;
          }

          &:before {
            transform: rotate(0);
          }

          &:after {
            transform: rotate(0);
          }
        }
      }
    }
  }

  > .main {
    display: flex;
    align-items: center;
    transition: all 0.2s;

    > .text {
      margin: 0 $text-arrow-space 0 0;
      line-height: 1;
    }

    > .the-arrow {
      position: relative;
    }
  }
}

.csH1 {
  align-self: flex-start;
}

// SVG Image to fill width of container with font of 'sketched'
.csImage {
  width: 100%;
  height: auto;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
