.sectionMenu {
  &.is-reveal {
    opacity: 1;
  }

  margin-top: 0;
  padding-top: 0;

  overflow: visible;

  opacity: 0;
}
