@font-face {
  font-family: avocado;
  src: url(fonts/avocadoRegular.ttf);
}

@font-face {
  font-family: avocadoBold;
  src: url(fonts/avocadoBold.ttf);
}

@font-face {
  font-family: bilingual;
  src: url(fonts/bilingual.ttf);
}

@font-face {
  font-family: sketched;
  src: url(fonts/Virgil.woff2);
}

.csImage {
  font-family: sketched;
}

.doublefont3 {
  position: relative;
  color: var(--accent-1);
  font-family: avocado;
  background: none;
}

.headerart {
  font-size: 18.5vw;
}

.doublefont3 {
  color: var(--accent-1);
  font-family: avocado;
  background: none;
}

.logoHeading {
  font-size: 2.5rem;
  font-weight: bolder;
  text-decoration: none;
  font-family: bilingual;
  color: var(--bg);
}
